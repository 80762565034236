import { Component, OnInit } from '@angular/core';
import {AuthService} from '../auth.service';
import {HistoryService} from '../history.service';
import {FormControl} from '@angular/forms';
import  * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { Md5 } from 'ts-md5';
declare var $ : any;

@Component({
  selector: 'app-history-manager',
  templateUrl: './history-manager.component.html',
  styleUrls: ['./history-manager.component.css']
})
export class HistoryManagerComponent implements OnInit {


  merchand : any;
  transactions : [];
  loading = false;
  daterange = new FormControl('');
  qty = new FormControl('');
  method = new FormControl('');
  keyword = new FormControl('');
  current = 1;
  message : any;
  lang : string;

  constructor(private auth : AuthService, private history : HistoryService) { }

  ngOnInit() {
    this.merchand = this.auth.getUserData();
    this.lang = localStorage.getItem("lang");
    $('#pickr07').dateRangePicker({
      autoClose: true,
      format: (this.lang === 'fr') ?  'DD/MM/YYYY HH:mm' :  'YYYY/MM/DD HH:mm',
      separator: (this.lang === 'fr') ?  ' au '  : ' to ',
      language: (this.lang === 'fr') ? 'fr' : 'en',
      startOfWeek: (this.lang === 'fr') ?  'dimanche' : 'sunday',
      time: {
        enabled: true
      },
      defaultTime: moment().startOf('day').toDate(),
      defaultEndTime: moment().endOf('day').toDate()
    });


    
    this.getHistory();
  }

  getDate(date : string){
    return moment(date).locale(this.lang).format('LLL');
  }

  getAccount(tr : any){
    var temp : string;
    temp = tr.account;
    if(temp.length > 5) {
      var lastCharactersPhone = temp.substring(temp.length - 2);
      var lastCharactersBank = temp.substring(temp.length-3);
      if(tr.method == 'bank') {
        return temp.substring(0,11)+"XXXXXXXXXXXX"+lastCharactersBank;
      }

      return temp.substring(0,2)+"............"+lastCharactersPhone;
    }

    return "XXXXXXXXX"
  }

  keyPress(e) {
    //See notes about 'which' and 'key'
    if (e.keyCode == 13) {
        this.getHistory();
        return false;
    }
  }

  getHistory() {
    this.loading = true;
    this.transactions = null;
    var data = {
      identify : environment.userEcommerceDomain,
      type : "web",
      store : this.merchand.code,
      begindate : "",
      enddate : "",
      cashier : "",
      current : this.current,
      max : this.qty.value,
      filterProvider : this.method.value,
      description : this.keyword.value,
      hash : ""
    };

    data.hash = ""+Md5.hashStr(data.store+this.merchand.apikey);
    
    if($("input#pickr07").val()) {
      var dates = $("input#pickr07").val().toString().split((this.lang === 'fr') ?  'au'  : 'to');
      if(dates[0]){
        data.begindate = (this.lang === 'fr') ? this.formatDateFr(dates[0].trim()) : dates[0].trim();
      }
      if(dates[1]){
        data.enddate = (this.lang === 'fr') ? this.formatDateFr(dates[1].trim()) : dates[1].trim();
      }
      
    }

    if(this.merchand.defaultCashier) {
      data.cashier = this.merchand.defaultCashier.userid;
    }

    this.history.getTransactions(data).subscribe((response : any) => {

      this.loading = false ;

      if(response) {
        if(response.code == 200) {
          this.transactions = response.result
          setTimeout(() => {
            $("input[type='search']").val("");
          }, 2000)
        }
      }
      
    })
    

  }

  formatDateFr(date : string) {
    if(date) {
      let t = date.split(" ");
      return t[0].split("/").reverse().join("/")+" "+t[1];
    }

    return "";
  }


  export() {

    delete this.message;
    var data = {
      identify : environment.userEcommerceDomain,
      type : "web",
      store : this.merchand.code,
      begindate : "",
      enddate : "",
      cashier : "",
      current : this.current,
      max : this.qty.value,
      filterProvider : this.method.value,
      description : this.keyword.value
    };
    
    $("#loadingModal2").modal("show");
    if($("input#pickr07").val()) {
      var dates = $("input#pickr07").val().toString().split((this.lang === 'fr') ?  'au'  : 'to');
      if(dates[0]){
        data.begindate = (this.lang === 'fr') ? this.formatDateFr(dates[0].trim()) : dates[0].trim();
      }
      if(dates[1]){
        data.enddate = (this.lang === 'fr') ? this.formatDateFr(dates[1].trim()) : dates[1].trim();
      }
      
    }

    if(this.merchand.defaultCashier) {
      data.cashier = this.merchand.defaultCashier.userid;
    }

    this.history.export(data).subscribe((response : any) => {

      if(response){
        if(response.code === 200 ){
          this.message = {
            type : 1,
            text : ""
          };
        } else {
          this.message = {
            type : 0,
            text : response.message
          };
        }
      }
    }, (error : any) =>{
      console.log(error);
      this.message = {
        type : 0,
        text : 'unknown_error'
      };
    })
    

  }

}
