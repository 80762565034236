import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {  throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class HttpService {

    private REST_API_SERVER = environment.targetAPI;
    private httpOptions = {
                headers: new HttpHeaders({
                  'Content-Type':  'application/json'
                })
            };

    constructor(private httpClient: HttpClient) { }

    handleError(error: HttpErrorResponse) {
        let errorMessage = 'Unknown error!';
        if (error.error instanceof ErrorEvent) {
          // Client-side errors
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // Server-side errors
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(errorMessage);
      }

    public get(url: string) {
      return this.httpClient.get(this.REST_API_SERVER + url).pipe(retry(1), catchError(this.handleError));
    }

    public post(url: string, data: string) {
        // tslint:disable-next-line: max-line-length
        return this.httpClient.post(this.REST_API_SERVER + url, data, this.httpOptions).pipe(retry(1), catchError(this.handleError));
    }
}
