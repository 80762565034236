import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class CashierService {

  constructor(private httpService : HttpService) { }

  get = (data : any) => {
    return this.httpService.post('ecommerce/cashier/get/', JSON.stringify(data));
  }

  list = (data : any) => {
    return this.httpService.post('ecommerce/cashier/getall/', JSON.stringify(data));
  }

  update = (data : any) => {
    return this.httpService.post('ecommerce/cashier/update/', JSON.stringify(data));
  }
}
