import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { HelperService } from '../helper.service';
import { HistoryService } from '../history.service';
import {Md5} from 'ts-md5/dist/md5';
import { environment } from 'src/environments/environment';
import { CashierService } from '../cashier.service';
import {Cashier} from '../classes/Cashier';
declare var $: any;

@Component({
  selector: 'app-cashiers',
  templateUrl: './cashiers.component.html',
  styleUrls: ['./cashiers.component.css']
})

export class CashiersComponent implements OnInit {

  merchand : any;
  message : {
    type : number,
    text :string
  };
  loading = true;
  cashier : Cashier;
  Cashiers : Array<Cashier>;
  Cashiers_temp : Array<Cashier>;
  email_error = false;
  phone_error = false;


  constructor(private auth : AuthService, private history : HistoryService, private router : Router,
    private helper : HelperService, private CahiersManager : CashierService) { }

  ngOnInit() {
    this.merchand = this.auth.getUserData();
    if(!this.merchand) this.router.navigateByUrl('logout');
    this.getCashiers();
    this.cashier = new Cashier(null);
  }

  eventCheck(event, option, i){
    let s = event.target.checked;

    switch (option) {
      case 'admin' : this.cashier.optionadmin = s; break;
      case 'dashboard' : this.cashier.optiondashboard = s; break;
      case 'balance' : this.cashier = s; break;
    }
  }

  edit() {
    $('#editModal').modal('show');
  }

  deleteMessenger(){

  }

  update(cashier : Cashier) {

    delete this.message;

    $('#updateModal').modal('show');

    var data = {
      identify : environment.userEcommerceDomain,
      type : "web",
      store : this.merchand.code,
      cashier : cashier,
      hash:""
    };


    data.hash = Md5.hashStr(this.merchand.apikey).toString();
    if(this.merchand.defaultCashier) {
      data.cashier = this.merchand.defaultCashier.userid;
    }


  }


  getCashiers(){

    delete this.message;

    var data = {
      identify : environment.userEcommerceDomain,
      type : "web",
      store : this.merchand.code,
      cashier : "",
      hash:""
    };


    data.hash = Md5.hashStr(this.merchand.apikey).toString();
    if(this.merchand.defaultCashier) {
      data.cashier = this.merchand.defaultCashier.userid;
    }

    this.loading = true;
    this.CahiersManager.list(data).subscribe((response : any) => {

      this.loading = false;

      if(response.code === 200){
        console.log()
        this.message = {
          type : 1,
          text : ""
        };
      }else {
        this.message = {
          //type : 4,
          type : 0,
          text : response.message
        }
      }
    }), (error : any) =>{
      console.log(error);
      this.message = {
        type : 0,
        text : 'unknown_error'
      };
    };

  }

}
