import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from './auth.service';
import {Md5} from 'ts-md5/dist/md5';
import {Router} from '@angular/router';
import { environment } from 'src/environments/environment';
import { FormControl } from '@angular/forms';
declare var $ : any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'AfrikEcommerce';
  merchand : any;
  message : any;
  password :string;
  newPassword : string;
  confirmPassword : string;
  showP = false;
  showCP = false;
  showNP = false;
  newEmail = new FormControl("");
  newPhone = new FormControl("");
  emailError = false;
  phoneError = false;
  savingContact = false;
  btntitle  : string;
  gettingKey = false;

  constructor(private auth : AuthService, private translate: TranslateService, router: Router) {
    const arrayLang = ['en', 'fr'];
    const defaultLang = 'fr';
    const userLang = navigator.language;
    let lang =  userLang.substring(0, 2 ).toLowerCase();
    this.merchand = this.auth.getUserData();

    if (arrayLang.lastIndexOf(lang) < 0) {
        lang = defaultLang;
    }
    if (localStorage.getItem('lang')) {
        translate.setDefaultLang(localStorage.getItem('lang'));
    } else {
        translate.setDefaultLang(defaultLang);
        localStorage.setItem("lang", defaultLang);
    }

  }

  spliter(s : string) {
    return s ? s.split(";") : [];
  }

  deleteMessager(){
    delete this.message;
  }

  toggle(e) {
    if(e === 'p') this.showP = !this.showP;
    if(e === 'np') this.showNP = !this.showNP;
    if(e === 'cp') this.showCP = !this.showCP;
  }


  changePassword() {
    var data = {
      identify : environment.userEcommerceDomain,
      type : "web",
      store : this.merchand.code,
      cashier : "",
      login : this.merchand.login,
      password : Md5.hashStr(this.password).toString(),
      newpassword : Md5.hashStr(this.newPassword).toString()
    };

    if(this.merchand.defaultCashier) {
      data.cashier = this.merchand.defaultCashier.userid;
    }

    $('#passwordChangeModal3').modal('hide');

    setTimeout(() => {
      $('#loaderModal3').modal('show');
    }, 300)


    this.auth.changePassword(data).subscribe((response : any) => {
      if(response){
        if(response.code === 200 ){
          this.message = {
            type : 1,
            text : ""
          };
        } else {
          this.message = {
            type : 0,
            text : response.message
          };
        }
      }
    }, (error : any) =>{
      console.log(error);
      this.message = {
        type : 0,
        text : 'unknown_error'
      };
    })
  }

  resetForm(){
    this.emailError = false;
    this.phoneError = false;
  }

  addEmail() {
    if(!this.newEmail.value.trim()) return;

    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!re.test(String(this.newEmail.value.trim()).toLowerCase())) {
      this.emailError = true;
      return;
    }

    var emails = this.merchand.email;
    if(!emails) {
      emails = "";
    } else {
      emails += ";";
    }
    emails += this.newEmail.value.trim();
    this.newEmail.setValue("");

    this.merchand.email = emails;
  }

  addPhone() {
    if(!this.newPhone.value) return;

    if(this.newPhone.value.toString().length < 9) {
      this.phoneError = true;
      return;
    }

    var contacts = this.merchand.contact;
    if(!contacts) {
      contacts = "";
    } else {
      contacts += ";";
    }

    contacts += this.newPhone.value.toString();
    this.newPhone.setValue("");

    this.merchand.contact = contacts;
  }

  deleteEmail(i : number) {
    let emails : Array<string>;
    emails = this.spliter(this.merchand.email);
    emails.splice(i,1);
    this.merchand.email = emails.join(";");
  }

  deletePhone(i : number) {
    let contacts : Array<string>;
    contacts = this.spliter(this.merchand.contact);
    contacts.splice(i,1);
    this.merchand.contact = contacts.join(";");
  }

  cancelChanges() {
    this.newEmail.setValue("");
    this.newPhone.setValue("");
    this.merchand = this.auth.getUserData();
    delete this.message;
  }

  copy() {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val(this.merchand.apikey).select();
    document.execCommand("copy");
    $temp.remove();
    this.btntitle = 'copied';
    setTimeout(() => {
      this.btntitle = 'copy';
    }, 2500);
  }

  saveChanges() {

    delete this.message;

    this.savingContact = true;

    var data = {
      identify : environment.userEcommerceDomain,
      type : "web",
      store : this.merchand.code,
      cashier : "",
      mobile : this.merchand.contact || "",
      email : this.merchand.email || "",
      hash : ""
    };

    if(this.merchand.defaultCashier) {
      data.cashier = this.merchand.defaultCashier.userid;
    }

    data.hash = Md5.hashStr(data.mobile+data.email+this.merchand.apikey).toString();

    this.auth.saveContacts(data).subscribe((response : any) => {

      this.savingContact = false;

      if(response.code === 200) {
        this.auth.saveUserData(this.merchand);
        this.message = {
          type : 1,
          text : ''
        }
      } else {
        this.message = {
          type : 0,
          text : response.message
        }
      }

    }, (error : any) =>{
      console.log(error);
      this.savingContact = false;
      this.message = {
        type : 0,
        text : 'unknown_error'
      };
    })
  }

  generate(){

    var data = {
      identify : environment.userEcommerceDomain,
      type : "web",
      store : this.merchand.code,
      cashier : "",
      hash : ""
    };

    if(this.merchand.defaultCashier) {
      data.cashier = this.merchand.defaultCashier.userid;
    }

    data.hash = Md5.hashStr(data.store+this.merchand.apikey).toString();

    this.gettingKey = true;

    this.auth.generateKey(data).subscribe((response : any) => {

      this.gettingKey = false;

      if(response.code === 200) {
        this.merchand.apikey = response.result;
        this.auth.saveUserData(this.merchand);
        this.message = {
          type : 1,
          text : ''
        }
      } else {
        this.message = {
          type : 0,
          text : response.message
        }
      }

    }, (error : any) =>{
      console.log(error);
      this.savingContact = false;
      this.message = {
        type : 0,
        text : 'unknown_error'
      };
    })

  }

}


