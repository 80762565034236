
export class Item {
  ids: number;
  title : string;
  details : string;
  units : number;
  price : number;

  constructor(data : any){
      if(data) {
        Object.assign(this,data);
        return;
      }

      this.title = "";
      this.details = "";
      this.units = 0;
      this.price = 0;
  }

  getTotal() {
    return this.units * this.price;
  }
}

export class Bill {
  ids : number;
  discount : number;
  tax : number;
  items : Array<Item>;
  store : string;
  client : {
    name : string;
    address : string;
    email : string;
  };
  status : string;
  date : string;
  url : string;
  amount : number;
  hash : string;
  datepaid : string;
  daptepaid : string;


  constructor(data : any){
    if(data){
      Object.assign(this,data);
      if(this.items) {
        for(var i = 0; i < this.items.length; i++){
          this.items[i] = new Item(this.items[i]);
        }

      }
    } else {
      this.discount = 0;
      this.tax = 0;
      this.items = [];
      this.date = "";
      this.url = "";
      this.hash = "";
      this.datepaid = "";
      this.status = "UNPAID";
      this.client = {
        name : "",
        address : "",
        email : ""
      };
    }
  }

  sbt(){
    let s = 0;
    this.items.forEach(e => {
      s += e.getTotal();
    });
    return s;
  }


  subTotal(){
    let s = 0;
    this.items.forEach(e => {
      s += e.getTotal();
    });
    return s;
  }

  getTotal() {
    return (this.sbt() - this.discount) * (1+this.tax/100);
  }

  updateItem(i : number, item : Item) {
    if(i > -1 ) {
      this.items[i] = item;
    }
  }

  deleteItem(index : number){
    if (index > -1) {
      this.items.splice(index, 1);
    }
  }

  addItem(data : {title,details,price,units}) {
    let item = new Item(null);
    item.title = data.title;
    item.units = data.units;
    item.details = data.details;
    item.price = data.price;

    this.items.push(item);
  }

}
