import { Component, OnInit } from '@angular/core';
import {AuthService} from '../auth.service';
import {HistoryService} from '../history.service';
import {Router} from '@angular/router';
import { environment } from 'src/environments/environment';
import { Md5 } from 'ts-md5';
declare var $ : any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  merchand : any;
  balance : number;
  currentRoute: any;
  password :string;
  newPassword : string;
  confirmPassword : string;
  message : any;
  loader = false;

  constructor(private auth : AuthService, private history : HistoryService, private router : Router) { }

  ngOnInit() {
    this.merchand = this.auth.getUserData();
    if(this.merchand) {
    this.merchand = this.auth.getUserData();
    this.showBalance();
    this.currentRoute = this.router.url;
    }
  }

  showBalance() {
    var data = {
      identify : environment.userEcommerceDomain,
      type : "web",
      store : this.merchand.code,
      cashier : "",
      hash : ""
    };

    data.hash = ""+Md5.hashStr(data.store+this.merchand.apikey).toString();

    if(this.merchand.defaultCashier) {
      data.cashier = this.merchand.defaultCashier.userid;
    }

    this.loader = true;

    this.history.getBalance(data).subscribe((response : any) => {
      if(response) {
        if(response.result) {
          var amt = response.result.maindeposit+"";
          amt = amt.replace(/\s/g, '');
          this.balance = this.filterFloat(amt);
          this.loader = false;
        }
      }
    })

  }


  toggleModal(b : string) {
    $('#passwordChangeModal').modal(b);
  }

  filterFloat (value) {
    if (/^(\-|\+)?([0-9]+(\.[0-9]+)?|Infinity)$/.test(value)) return Number(value);
    return NaN;
}
      

}
