import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import {FormControl} from '@angular/forms';
import {Md5} from 'ts-md5/dist/md5';
import {Router} from '@angular/router';
import { environment } from 'src/environments/environment';
declare var $ : any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginError = false;
  passwordError = false;
  loading = false;
  disabledBtn = false;
  login_password_incorrect = false;
  service_issue = false;
  login = new FormControl('');
  password = new FormControl('');
  merchand : any;
  merchandRequest : string;
  message : any;
  login_reset : string;
  showP = false;

  constructor(private auth : AuthService, private router : Router) { }

  ngOnInit() {
    this.merchand = this.auth.getUserData();
    if(this.merchand) this.router.navigateByUrl('dashboard');
    this.merchandRequest = environment.merchandRequest;
  }

  resetFormErrors() {
    this.passwordError = false;
    this.loginError = false;
    this.login_password_incorrect = false;
  }

  toggle() {
    this.showP = !this.showP;
  }

  signin() {
    if(!this.login.value) this.loginError = true;
    if(!this.password.value) this.passwordError = true;

    if(this.loginError || this.passwordError) return;

    this.loading = true;
    this.disabledBtn = true;

    this.auth.login(this.login.value.toString(), Md5.hashStr(this.password.value.toString()).toString()).subscribe( (response : any) => {

      this.loading = false;
      if(response) {
        if(response.code === 200) {
          var merchand = response.result.store;
          delete response.result.store;
          merchand.defaultCashier = response.result;
          this.auth.saveUserData(merchand);
          this.router.navigateByUrl('/dashboard');
        } else {
          this.login_password_incorrect = true;
          this.disabledBtn = false;
        }
      }else {
        this.service_issue = false;
        this.disabledBtn = false;
      }
    },
    (error : any) => {

      if(error){
        this.service_issue = false;
        this.loading = false;
        this.disabledBtn = false;
      }

    })

  }

  reset(){
    delete this.message;
    $('#passwordResetModal').modal('hide');

    setTimeout(() => {
      $('#loaderModal').modal('show');
    }, 300)

    var data = {
      identify : "",
      type : "web",
      email : this.login_reset
    };

    this.auth.resetPassword(data).subscribe((response : any) => {
      if(response){
        if(response.code === 200 ){
          this.message = {
            type : 1,
            text : ""
          };
        } else {
          this.message = {
            type : 0,
            text : response.message
          };
        }
      }
    }, (error : any) =>{
      console.log(error);
      this.message = {
        type : 0,
        text : 'unknown_error'
      };
    })
  }

}
