import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class OperationService {

  constructor(private httpService: HttpService) {  }

  withdraw(data : any) {
    return this.httpService.post('ecommerce/refund/', JSON.stringify(data));
  }

  getRefunds(data : any) {
    return this.httpService.post('ecommerce/get/refund/', JSON.stringify(data));
  }

  validateRefund(data : any) {
    return this.httpService.post('ecommerce/validate/refund/', JSON.stringify(data));
  }

  rejectRefund(data : any) {
    return this.httpService.post('ecommerce/reject/refund/', JSON.stringify(data));
  }



  getName(account : any, provider : string) {
    return this.httpService.post('money/getname/', JSON.stringify({account : account, provider : provider}));
  }
}
