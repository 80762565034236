import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'counter'
})
export class CounterPipe implements PipeTransform {

  transform(items: any[], filter: string): any {
    if (!items || !filter) {
        return 0;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    let array =  items.filter(item => item.status === filter);
    return array.length;
  }

}
