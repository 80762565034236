export class Cashier {
  name : string;
  userid: string;
  optiondashboard : boolean;
  optionbalance : boolean;
  optionadmin : boolean;

  constructor(data : any){
    if(data){
      Object.assign(this,data);
    } else {
      this.userid = data.userid;
      this.name = data.name;
      this.optiondashboard = data.optiondashboard;
      this.optionbalance = data.optionbalance;
      this.optionadmin = data.optionadmin;
    }
  }

  removeOptiondashboard(){
    this.optiondashboard = false;
  }

  removeOptionbalance(){
    this.optionbalance = false;
  }

  removeAdmin(){
    this.optionadmin = false;
  }

  addOptiondashboard(){
    this.optiondashboard = true;
  }

  addOptionbalance(){
    this.optionbalance = true;
  }

  addOptionadmin(){
    this.optionadmin = true;
  }
}
