import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class BillService {

  constructor(private httpService: HttpService) {  }

  create = (data : any) => {
    return this.httpService.post('ecommerce/bill/create/', JSON.stringify(data));
  }

  update = (data : any) => {
    return this.httpService.post('ecommerce/bill/update/', JSON.stringify(data));
  }

  delete = (data : any) => {
    return this.httpService.post('ecommerce/bill/delete/', JSON.stringify(data));
  }

  getAll = (data : any) => {
    return this.httpService.post('ecommerce/bill/getall/', JSON.stringify(data));
  }

  getBill = (data : any) => {
    return this.httpService.post('ecommerce/bill/get/', JSON.stringify(data));
  }
}
