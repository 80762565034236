import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { HelperService } from '../helper.service';
import {FormControl} from '@angular/forms';
import { Pot } from '../classes/pot';
import { NgNavigatorShareService } from 'ng-navigator-share';
declare var $:any;

@Component({
  selector: 'app-funding',
  templateUrl: './funding.component.html',
  styleUrls: ['./funding.component.css']
})
export class FundingComponent implements OnInit {

  merchand : any;
  saving = false;
  pot : Pot;
  message : any;
  formModel : any;
  videolinkError = false;
  titleError = false;
  btntitle : string;

  constructor(private auth : AuthService, private helper : HelperService, private ngNavigatorShareService : NgNavigatorShareService) { }

  ngOnInit() {
    this.merchand = this.auth.getUserData();
    this.pot = new Pot(null);
    $('#fundEditModal').modal('show');
  }


  Showme(){
    console.log(this.formModel);
  }

  public options: Object = {
    charCounterCount: true,
    toolbarButtons: ['bold', 'italic', 'underline', 'paragraphFormat','alert'],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'paragraphFormat','alert'],
    toolbarButtonsSM: ['bold', 'italic', 'underline', 'paragraphFormat','alert'],
    toolbarButtonsMD: ['bold', 'italic', 'underline', 'paragraphFormat','alert'],
  };


  canUseNavigatorShare(){
    return this.helper.canUseNavigatorShare();
  }

  isMobile(){
    return this.helper.isMobile();
  }

  share(url : string){
    this.ngNavigatorShareService.share({
      title:  this.merchand.name,
      text: 'AfrikEcommerce',
      url: url,
    }).then( (response) => {
      console.log(response);
    })
    .catch( (error) => {
      console.log(error);
    });
  }

  copy(url : string) {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val(url).select();
    document.execCommand("copy");
    $temp.remove();
    this.btntitle = 'copied';
    setTimeout(() => {
      this.btntitle = 'copy';
    }, 2500);
  }

  numberize(i: number){
    return i < 10 ? "0"+i : i;
  }

  deleteMessager(){
    delete this.message;
    delete this.pot;
  }

}
