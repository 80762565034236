import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import {FormControl} from '@angular/forms';
import {AuthService} from '../auth.service';
import {Service, Field, Option} from '../classes/Service';
import  * as moment from 'moment';
import '../helper/icons';
import { ServicesService } from '../services.service';
import { HelperService } from '../helper.service';
import { environment } from 'src/environments/environment';
import { NgNavigatorShareService } from 'ng-navigator-share';
import suneditor from 'suneditor';

// How to import plugins

import plugins from 'suneditor/src/plugins'


import { DOCUMENT } from '@angular/common';
declare var $ : any;

@Component({
  selector: 'app-services-manager',
  templateUrl: './services-manager.component.html',
  styleUrls: ['./services-manager.component.css']
})
export class ServicesManagerComponent implements OnInit {

  typeList = [
    {
      value : 'number',
      name : "numeric"
    },
    {
      value : 'text',
      name : "text"
    },
    {
      value : 'list',
      name : "list"
    },
    {
      value : 'textarea',
      name : 'textarea'
    },
    {
      value : 'date',
      name : 'date'
    },
    {
      value : 'email',
      name : 'email'
    },
    {
      value : 'file',
      name : 'file'
    },
    {
      value : 'time',
      name : 'time'
    }
  ];
  rootUrl : string;
  merchand : any;
  loading : any;
  services : Array<Service>;
  service : Service;
  creating = false;
  editing = false;
  publishing = false;
  disabling = false;
  saving = false;
  fcreating = false;
  fediting = false;
  show_field_error = false;
  show_service_error = false;
  field : Field;
  editedField : number;
  editedService : number;
  loader_all = true;
  message : {type : number, text : string};
  btntitle = 'copy';
  html : string;
  textemail_error = false;
  loading_bill = false;
  editor : any;
  amountInput : FormControl;
  amount_error =  false;
  option_element : Option;
  option_used_error = false;
  option_title_error = false;
  option_amount_error = false;
  element : FormControl;
  deleting = false;


  constructor(private auth : AuthService, private serviceManager : ServicesService,
    private helper : HelperService, private ngNavigatorShareService: NgNavigatorShareService,
    private renderer2 : Renderer2, @Inject(DOCUMENT) private _document) { }

  ngOnInit() {
    this.merchand = this.auth.getUserData();
    this.getAll();
    this.rootUrl = environment.rootUrl;
    this.amountInput = new FormControl();
    this.element = new FormControl();
  }


  copy() {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val(this.getUrl()).select();
    document.execCommand("copy");
    $temp.remove();
    this.btntitle = 'copied';
    setTimeout(() => {
      this.btntitle = 'copy';
    }, 2500);
  }

  changeStatus(evt : any) {
    this.service.isamount = !evt.target.checked;
    if(!this.service.isamount) this.service.haveSelectAmount = false;
    this.service.deleteAmount();
  }

  setTextEmail(s : string) {
    this.service.textemail = s;
  }

  saveFixedAmount(){
    this.service.deleteAmount();
    this.service.amount = this.amountInput.value;
    if(this.service.amount > 0) {
      this.service.isamount = false;
      this.service.haveSelectAmount = false;
      $('#fixedAmountModal').modal('hide');
      this.amount_error = false;
    }else {
      this.amount_error = true;
    }
  }



  initSelectAmount(){
    this.field = new Field(null);
    this.field.name = "amount";
    this.field.type = 'list';
    this.field.label = 'Amount';

    this.option_element = new Option(null);
  }

  saveOption() {
    this.option_title_error = false;
    this.option_used_error = false;
    this.option_amount_error = false;

    if(this.option_element.title.length < 1) {
      this.option_title_error = true;
      return;
    }

    if(!this.field.availableOption(this.option_element.title) ) {
      this.option_used_error = true;
      return;
    }

    if(this.option_element.amount < 100) {
      this.option_amount_error = true;
      return;
    }

    this.field.addOption(this.option_element);
    this.option_element = new Option(null);
  }

  deleteOption(i : number){
    this.field.optionsList.splice(i,1);
  }

  editOptionList(){
    this.option_element = new Option(null);
    this.field = new Field(this.service.formBuilds[this.service.getAmountField()]);
  }

  removedOptions(){
    this.service.deleteAmount();
    this.service.haveSelectAmount = false;
  }

  removeFixedAmount(){
    this.service.amount = 0;
    this.service.isamount = true;
  }

  saveSelectAmount(){
    this.service.isamount = true;
    this.service.haveSelectAmount = true;
    $("#SelectAmountModal").modal('hide');
    if(this.service.getAmountField() > -1) {
      this.service.formBuilds[this.service.getAmountField()] = new Field(this.field);
    } else { console.log('amount field is ok ');
      this.service.formBuilds.unshift(new Field(this.field));
    }
    delete this.field;
  }

  setEditor(){
    if(!(this.editor == null)) this.editor.destroy();
    this.editor =  suneditor.create('textemail', {
      plugins: plugins,
      buttonList: [
          ['undo', 'redo'],
          ['font', 'fontSize', 'formatBlock'],
          ['paragraphStyle', 'blockquote'],
          ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
          ['fontColor', 'hiliteColor', 'textStyle'],
          ['removeFormat'],
          '/', // Line break
          ['outdent', 'indent'],
          ['align', 'horizontalRule', 'list', 'lineHeight'],
          ['table', 'link', 'image', 'video', 'audio' /** ,'math' */], // You must add the 'katex' library at options to use the 'math' plugin.
          /** ['imageGallery'] */ // You must add the "imageGalleryUrl".
          ['fullScreen', 'showBlocks', 'codeView'],
          ['preview', 'print'],
          ['save', 'template']
      ]
    })

      var setText = (s) => this.setTextEmail(s);
      var service = this.service;
      this.editor.onChange = function (contents, core) {
        if(service) {
          setText(contents);
        }
      }
  }

  changeEmailStatus(evt : any){
    this.service.withemail = evt.target.checked;
    setTimeout(()=> {
      this.setEditor()
    },150);
  }

  changeStatusAmoutList(evt : any) {
    if($("#isselectamount").val()) {
      this.field = new Field(null);
      this.field.type = 'list';
      this.field.name = 'amount';
      this.field.label = 'Amount'
      this.fcreating = true;
      $("#fieldAmountModal").modal("show");
      this.service.haveSelectAmount = false;
      $("#isselectamount").prop("checked", false);
    } else {
      console.log('delete amount');
      this.service.deleteAmount();
    }

  }

  requiredStatus(checked : boolean){
    this.field.required = checked;
  }

  onKeydown(event) {
    if (event.key === "Enter") {
      if(this.element.value.trim().length > 0) {
        this.field.selectListElement.push(this.element.value);
        this.element.setValue('');
      }
    }
  }

  deleteElement(i : number){
    this.field.selectListElement.splice(i,1);
  }

  cancelAll() {
    this.field = null;
    this.editing = false;
    this.creating = false;
    this.fcreating = false;
    this.fediting = false;
    this.creating = false;
    this.editing = false;
    this.publishing = false;
    this.saving = false;
    this.disabling = false;
    this.fcreating = false;
    this.fediting = false;
    this.show_field_error = false;
    this.show_service_error = false;
    delete this.service;
    this.cancel_deletion();
  }

  createService() {
    this.cancelAll();
    this.creating = true;
    this.service = new Service(null);
    this.setEditor();
  }

  editService(i : number) {
    this.cancelAll();
    this.loading_bill = true;
    this.editedService = i;

    var data = {
      identify : environment.userEcommerceDomain,
      type : "web",
      store : this.merchand.code,
      cashier : "",
      slug : this.services[i].slug
    };
    if(this.merchand.defaultCashier) {
      data.cashier = this.merchand.defaultCashier.userid;
    };

    this.serviceManager.get(data).subscribe((response : any) => {
      this.loading_bill = false;
      if(response) {
        if(response.code === 200 ){
          this.service = new Service(response.result);
          this.editing = true;
          setTimeout(() => {
            this.setEditor()
          },150);

          this.message = {
            type : 1,
            text : ""
          };
        } else {
          this.message = {
            type : 0,
            text : response.message
          };
        }
      }
    }, (error : any) =>{
      console.log(error);
      this.message = {
        type : 0,
        text : 'unknown_error'
      };
    })
  }

  deleteField(i : number) {
    if(i > -1) {
      if(this.service) {
        this.service.deleteField(i);
      }
    }
  }

  editField(i : number) {
    this.editedField = i;
    this.fediting = true;
    this.fcreating = false;
    if(this.service) {
      this.field = new Field(this.service.formBuilds[i]);
    }
    $("#fieldNewModal").modal('show');
  }

  resetFieldStatus(){
    this.fcreating = false;
    this.fediting = false;
  }

  newField() {
    this.fediting = false;
    this.fcreating = true;
    this.show_field_error = false;
    this.field = new Field(null);
  }

  isSelect(val : string) {
    if(val === 'list') {
      this.field.isselect = true;
    } else {
      this.field.isselect = false;
      if(val === 'textarea') {
        this.field.istextarea = true;
      } else {
        this.field.istextarea = false;
      }
    }
  }

  saveField() {
    this.show_field_error = true;
    if(!this.field.isCorrect()) return;

    $("#fieldNewModal").modal('hide');
    $("#fieldAmountModal").modal('hide');

    if(this.field.name === 'amount') {
      this.service.haveSelectAmount = true;
      this.service.isamount = true;
    }
    if(this.fcreating){
      this.service.formBuilds.push( new Field(this.field));
    } else {
      if(this.fediting) {
        this.service.formBuilds[this.editedField] = this.field;
        this.fediting = false;
      }
    }

    delete this.field;
  }


  saveIt(){
    delete this.message;
    this.show_service_error = true;
    this.textemail_error = !this.service.isCorrect() && this.service.withemail && !this.service.textemail;
    if(!this.service.isCorrect()) return;
    this.service.setOptions();
    this.service.setNames();
    $('#loadingModal').modal('show');
    this.saving = true;

    var data = {
      identify : environment.userEcommerceDomain,
      type : "web",
      store : this.merchand.code,
      cashier : "",
      service : this.service
    };

    if(this.merchand.defaultCashier) {
      data.cashier = this.merchand.defaultCashier.userid;
    };

    var todo = null;
    if(this.service.ids) {
      todo = this.serviceManager.update;
    } else {
      todo = this.serviceManager.save;
    }

    todo(data).subscribe((response : any) => {
      if(response){
        if(response.code === 200 ){

          if(this.creating) {
            this.service.status = 'DRAFT';
            this.services.unshift(this.service);
            $("#serviceContainer>div.active").removeClass("active");
          }
          if(this.editing) {
            this.services[this.editedService].status = response.result.status;
          }
          this.saving = false;
          this.editing = true;
          this.creating = false;

          this.message = {
            type : 1,
            text : ""
          };
        } else {
          this.message = {
            type : 0,
            text : response.message
          };
        }
      }
    }, (error : any) =>{
      console.log(error);
      this.message = {
        type : 0,
        text : 'unknown_error'
      };
    })
  }

  publishIt(){
    delete this.message;
    this.show_service_error = true;
    if(!this.service.isCorrect()) return;
    this.service.setOptions();
    this.service.setNames();
    $('#loadingModal').modal('show');
    this.saving = false;
    this.publishing = true;

    var data = {
      identify : environment.userEcommerceDomain,
      type : "web",
      store : this.merchand.code,
      cashier : "",
      service : this.service
    };
    if(this.merchand.defaultCashier) {
      data.cashier = this.merchand.defaultCashier.userid;
    }

    this.serviceManager.publish(data).subscribe((response : any) => {
      if(response){
        if(response.code === 200 ){
          this.service = new Service(response.result);
          this.services[this.editedService].status = "PUBLISHED";
          this.publishing = false;
          this.message = {
            type : 1,
            text : ""
          };
        } else {
          this.message = {
            type : 0,
            text : response.message
          };
        }
      }
    }, (error : any) =>{
      console.log(error);
      this.message = {
        type : 0,
        text : 'unknown_error'
      };
    })
  }

  disableIt(){
    delete this.message;
    this.show_service_error = true;
    if(!this.service.isCorrect()) return;
    this.service.setOptions();
    this.service.setNames();
    $('#loadingModal').modal('show');
    this.saving = false;
    this.publishing = false;
    this.disabling = true;

    var data = {
      identify : environment.userEcommerceDomain,
      type : "web",
      store : this.merchand.code,
      cashier : "",
      service : this.service
    };
    if(this.merchand.defaultCashier) {
      data.cashier = this.merchand.defaultCashier.userid;
    }

    this.serviceManager.disbable(data).subscribe((response : any) => {
      if(response){
        if(response.code === 200 ){
          this.service = new Service(response.result);
          this.services[this.editedService].status = 'DISABLED';
          this.disabling = false;
          this.message = {
            type : 1,
            text : ""
          };
        } else {
          this.message = {
            type : 0,
            text : response.message
          };
        }
      }
    }, (error : any) =>{
      console.log(error);
      this.message = {
        type : 0,
        text : 'unknown_error'
      };
    });
  }

  cancel_deletion(){
    this.deleting = false;
  }

  deleteIt() {

    if(!this.deleting) {
      this.deleting = true;
      $('#confirmModal').modal('show');
      return;
    }

    delete this.message;
    $('#delModal').modal('show');

    var data = {
      identify : environment.userEcommerceDomain,
      type : "web",
      store : this.merchand.code,
      cashier : "",
      service : this.service
    };
    if(this.merchand.defaultCashier) {
      data.cashier = this.merchand.defaultCashier.userid;
    }

    this.serviceManager.delete(data).subscribe((response : any) => {
      if(response){
        if(response.code === 200 ){
          delete this.service;
          this.services = [];
          this.getAll();
          this.deleting = false;
          this.message = {
            type : 1,
            text : ""
          };
        } else {
          this.message = {
            type : 0,
            text : response.message
          };
        }
      }
    }, (error : any) =>{
      console.log(error);
      this.message = {
        type : 0,
        text : 'unknown_error'
      };
    });


  }

  getPreview(){
    delete this.message;
    delete this.html;
    $('#previewModal').modal('show');

    var data = {
      identify : environment.userEcommerceDomain,
      type : "web",
      store : this.merchand.code,
      cashier : "",
      service : this.service
    };
    if(this.merchand.defaultCashier) {
      data.cashier = this.merchand.defaultCashier.userid;
    }

    this.serviceManager.preview(data).subscribe((response : any) => {
      if(response){
        if(true ){
          this.html = response.result;
        } else {
          this.message = {
            type : 0,
            text : response.message
          };
        }
      }
    }, (error : any) =>{
      console.log(error);
      this.message = {
        type : 0,
        text : 'unknown_error'
      };
    })
  }

  getAll() {
    var data = {
      identify : environment.userEcommerceDomain,
      type : "web",
      store : this.merchand.code,
      cashier : ""
    };
    if(this.merchand.defaultCashier) {
      data.cashier = this.merchand.defaultCashier.userid;
    }

    this.loader_all = true;

    this.serviceManager.getList(data).subscribe((response : any) => {
      if(response){
        if(response.code === 200 ){
          this.services = response.result;
          for (let index = 0; index < this.services.length; index++) {
            this.services[index] = new Service(this.services[index]);
            this.services[index].setValues();
          }
          this.message = {
            type : 1,
            text : ""
          };
        } else {
          this.message = {
            type : 0,
            text : response.message
          };
        }
      }
      this.loader_all = false;
    }, (error : any) =>{
      console.log(error);
      this.loader_all = false;
      this.message = {
        type : 0,
        text : 'unknown_error'
      };
    })
  }

  isMobile() {
    return this.helper.isMobile();
  }

  getUrl(){
    return this.rootUrl+this.merchand.slug+'/'+this.service.slug;
  }

  canUseNavigatorShare(){
    return this.helper.canUseNavigatorShare();
  }

  share(){
    this.ngNavigatorShareService.share({
      title:  this.merchand.name,
      text: 'AfrikEcommerce',
      url: this.getUrl(),
    }).then( (response) => {
      console.log(response);
    })
    .catch( (error) => {
      console.log(error);
    });
  }

}
