import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }


  getWidth() {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );
  }

  isMobile(): boolean {
    var isMobile = {
      Android: function () {
        return navigator.userAgent.match(/Android/i);
      },
      BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
      },
      iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
      },
      Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
      },
      Windows: function () {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
      },
      any: function () {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
      }
    };

    return (isMobile.any() ? true : false) ;
  }

  canUseNavigatorShare() {
    var browser = {
      Safari : function() {
        return (navigator.userAgent.toLowerCase().indexOf('safari/') > -1) && !(navigator.userAgent.toLowerCase().indexOf('chrome/') > -1) ;
      },
      Chrome : function() {
        return /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
      }
    };


    return (browser.Chrome() && this.isMobile())  || browser.Safari();
  }

  isYoutubeLink(url : string) {
    if (url != undefined || url != '') {
      var p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      if(url.match(p)){
          return url.match(p)[1];
      }
      return false;
    }

    return false;
  }
}
