import { Component, OnInit } from '@angular/core';
import {AuthService} from '../auth.service';
import {Md5} from 'ts-md5/dist/md5';
import {Router} from '@angular/router';
import { environment } from 'src/environments/environment';
declare var $ : any;

@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.css']
})
export class AsideComponent implements OnInit {
  merchand : any;
  currentRoute: any;
  message : any;
  password :string;
  newPassword : string;
  confirmPassword : string;

  constructor(private auth : AuthService, private router : Router) { }

  ngOnInit() {
    this.merchand = this.auth.getUserData();
    this.currentRoute = this.router.url;
  }

  changePassword() {
    var data = {
      identify : environment.userEcommerceDomain,
      type : "web",
      store : this.merchand.code,
      cashier : "",
      password : Md5.hashStr(this.password).toString(),
      newpassword : Md5.hashStr(this.newPassword).toString()
    };

    if(this.merchand.defaultCashier) {
      data.cashier = this.merchand.defaultCashier.userid;
    }

    $('#passwordChangeModal3').modal('hide');

    setTimeout(() => {
      $('#loaderModal3').modal('show');
    }, 300)


    this.auth.changePassword(data).subscribe((response : any) => {
      if(response){
        if(response.code === 200 ){
          this.message = {
            type : 1,
            text : ""
          };
        } else {
          this.message = {
            type : 0,
            text : response.message
          };
        }
      }
    }, (error : any) =>{
      console.log(error);
      this.message = {
        type : 0,
        text : 'unknown_error'
      };
    })
  }

}
