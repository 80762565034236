import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  constructor(private httpService: HttpService) {  }

  save = (data : any) => {
    return this.httpService.post('ecommerce/service/save/', JSON.stringify(data));
  }

  getList(data : any) {
    return this.httpService.post('ecommerce/service/list/', JSON.stringify(data));
  }

  publish(data : any) {
    return this.httpService.post('ecommerce/service/publish/', JSON.stringify(data));
  }

  preview(data : any) {
    return this.httpService.post('ecommerce/service/visualize/', JSON.stringify(data));
  }

  disbable(data : any) {
    return this.httpService.post('ecommerce/service/disabled/', JSON.stringify(data));
  }

  update = (data : any) => {
    return this.httpService.post('ecommerce/service/update/', JSON.stringify(data));
  }

  get = (data : any) => {
    return this.httpService.post('ecommerce/service/get/', JSON.stringify(data));
  }

  delete = (data : any) => {
    return this.httpService.post('ecommerce/service/delete/', JSON.stringify(data));
  }
}
