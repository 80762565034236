export class Pot{
  ids : number;
  title : string;
  dateline : string;
  url : string;
  videourl : string;
  imageurl : string;
  targetamount : number;
  hash : string;
  balance : number;
  status : string;

  constructor(data : any) {
    if(data) {
      Object.assign(this,data);
    }else {
      this.title = "";
      this.dateline = "";
      this.targetamount = 0;
      this.hash = "";
      this.balance = 0;
      this.status = 'CREATED';
      this.videourl = "";
      this.imageurl = "";
    }
  }

  getTitle(){
    return (this.title.length > 15) ? this.title.substr(0, 14) : this.title;
  }
}
