import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  constructor(private httpService: HttpService) {  }

  getTransactions(data : any) {
    return this.httpService.post('ecommerce/history/', JSON.stringify(data));
  }

  export(data : any) {
    return this.httpService.post('ecommerce/history/export/', JSON.stringify(data));
  }

  getBalance(data : any) {
    return this.httpService.post('ecommerce/balance/', JSON.stringify(data)); 
  }
}
